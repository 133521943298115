<template>
  <div
    v-editable="blok"
    class="CoverBlock"
    :class="{
      'heroIfFirst': blok.heroIfFirst,
    }"
  >
    <component
      :is="$sbLinkCheck(blok.link) ? NuxtLink:'div'"
      :to="$sbLinkFix(blok.link)"
      :target="$sbLinkTarget(blok.link)"
    >
      <div class="w-full relative">
        <div
          :style="`background-color: ${blok.backgroundColor.color}`"
          class="absolute w-full h-full"
        >
          <div
            v-if="blok.imageOrBackgroundVideo && blok.imageOrBackgroundVideo.filename"
            class="w-full h-full absolute top-0 left-0"
          >
            <video
              v-if="checkIfVideoFile(blok.imageOrBackgroundVideo.filename)"
              width="w-full"
              height="h-full"
              class="w-full h-full object-cover"
              loop
              muted
              autoplay
              playsinline
            >
              <source :src="blok.imageOrBackgroundVideo.filename" type="video/mp4">
            </video>
            <nuxt-img
              v-else-if="blok.imageOrBackgroundVideo && blok.imageOrBackgroundVideo.filename"
              preset="standard"
              sizes="sm:100vw desk:1024px deskStd:1372px deskLarge:1600px"
              class="h-full w-full object-cover"
              :preload="isTop"
              :loading="isTop ? 'eager':'lazy'"
              :src="blok.imageOrBackgroundVideo.filename"
              :alt="blok.imageOrBackgroundVideo.alt"
            />
          </div>
          <div
            v-if="blok.desktopImageOrBackgroundVideo && blok.desktopImageOrBackgroundVideo.filename"
            class="hidden desk:block w-full h-full absolute top-0 left-0"
          >
            <video
              v-if="checkIfVideoFile(blok.desktopImageOrBackgroundVideo.filename)"
              width="w-full"
              height="h-full"
              class="w-full h-full object-cover"
              loop
              muted
              autoplay
              playsinline
            >
              <source :src="blok.desktopImageOrBackgroundVideo.filename" type="video/mp4">
            </video>
            <nuxt-img
              v-else-if="blok.desktopImageOrBackgroundVideo && blok.desktopImageOrBackgroundVideo.filename"
              preset="standard"
              sizes="sm:100vw desk:1260px"
              class="h-full w-full object-cover"
              :preload="isTop"
              :loading="isTop ? 'eager':'lazy'"
              :src="blok.desktopImageOrBackgroundVideo.filename"
              :alt="blok.desktopImageOrBackgroundVideo.alt"
            />
          </div>
        </div>
        <div class="layout-container grid grid-cols-2 px-32 py-32 desk:px-0">
          <div
            class="relative row-start-1 row-end-1 h-full"
            :class="{
              'aspect-landscape desk:aspect-ultra-wide col-start-1 col-span-2': blok.design === 'oneColumn',
              'aspect-landscape col-start-1 col-span-2 desk:col-start-2 desk:col-span-1': blok.design !== 'oneColumn',
            }"
          >
            <nuxt-img
              v-if="blok.desktopImage && blok.desktopImage.filename"
              preset="standard"
              sizes="sm:100vw desk:1024px deskStd:1372px deskLarge:1600px"
              class="hidden desk:block h-full w-full object-cover"
              :preload="isTop"
              :loading="isTop ? 'eager':'lazy'"
              :src="blok.desktopImage.filename"
              :alt="blok.desktopImage.alt"
            />
            <!-- image on top -->
            <nuxt-img
              v-if="blok.image && blok.image.filename"
              preset="standard"
              :sizes="spashImageSizes"
              class="h-full w-full object-cover"
              :preload="isTop"
              :loading="isTop ? 'eager':'lazy'"
              :class="{
                'desk:hidden' : blok.desktopImage && blok.desktopImage.filename,
              }"
              :src="blok.image.filename"
              :alt="blok.image.alt"
            />
            <div
              v-if="showTapes"
              class="TapeWrapper w-full h-full absolute top-0 left-0 type-headline-xxs desk:type-headline-xs"
              :class="{
                'text-darkest': isLight(blok.tapeBackgroundColor.color),
                'text-lightest': !isLight(blok.tapeBackgroundColor.color)
              }"
            >
              <div
                :style="{backgroundColor : blok.tapeBackgroundColor.color}"
                class="TapeLeft flex items-center justify-center pl-[3px] w-24 desk:w-32 h-full absolute top-0 bottom-0 left-0 overflow-hidden"
              >
                <div class="flex gap-8 desk:gap-12 whitespace-nowrap -rotate-90">
                  <span
                    v-for="n in 34"
                    :key="n"
                    class="odd:font-normal"
                  >
                    {{ blok.tapeText }}
                  </span>
                </div>
              </div>
              <div
                :style="{backgroundColor : blok.tapeBackgroundColor.color}"
                class="TapeBottom flex items-center justify-center pb-[3px] w-full h-24 desk:h-32 absolute right-0 bottom-0 left-0 overflow-hidden"
              >
                <div class="flex gap-8 desk:gap-12 whitespace-nowrap rotate-180">
                  <span
                    v-for="n in 34"
                    :key="n"
                    class="odd:font-normal"
                  >
                    {{ blok.tapeText }}
                  </span>
                </div>
              </div>
              <div
                :style="{backgroundColor : blok.tapeBackgroundColor.color}"
                class="TapeRight flex items-center justify-center pr-[3px] w-24 desk:w-32 h-full absolute top-0 right-0 bottom-0 overflow-hidden"
              >
                <div class="flex gap-8 desk:gap-12 whitespace-nowrap rotate-90">
                  <span
                    v-for="n in 34"
                    :key="n"
                    class="odd:font-normal"
                  >
                    {{ blok.tapeText }}
                  </span>
                </div>
              </div>
              <div
                :style="{backgroundColor : blok.tapeBackgroundColor.color}"
                class="TapeTop flex items-center justify-center pt-[3px] w-full h-24 desk:h-32 absolute top-0 left-0 overflow-hidden"
              >
                <div class="flex gap-8 desk:gap-12 whitespace-nowrap">
                  <span
                    v-for="n in 34"
                    :key="n"
                    class="odd:font-normal"
                  >
                    {{ blok.tapeText }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="absolute -top-16 -left-16 desk:-top-24 desk:-left-24 w-64 h-64 -rotate-12"
              :class="{
                'desk:w-112 desk:h-112': blok.design === 'oneColumn',
                'desk:w-96 desk:h-96': blok.design !== 'oneColumn',
                'spin': blok.spinSplash,
              }"
            >
              <div
                v-if="blok.splashTopText || blok.splashText"
                :style="{backgroundColor : blok.splashBackgroundColor.color}"
                class="h-full w-full rounded-full flex flex-col items-center justify-center gap-2 text-center"
                :class="
                  { 'text-darkest': isLight(blok.splashBackgroundColor.color),
                    'text-lightest': !isLight(blok.splashBackgroundColor.color) }"
              >
                <div
                  v-if="blok.splashTopText"
                  class="-mt-2 mb-2 type-tagline-xxs desk:type-tagline"
                >
                  {{ blok.splashTopText }}
                </div>
                <div
                  v-if="blok.splashText"
                  class="type-headline-xs leading-single"
                  :class="{
                    'desk:type-headline-xl': blok.design === 'oneColumn',
                    'desk:type-headline-lg': blok.design !== 'oneColumn',
                  }"
                >
                  {{ blok.splashText }}
                </div>
              </div>
              <div v-else class="w-full h-full rounded-full overflow-hidden">
                <nuxt-img
                  v-if="blok.splashImage && blok.splashImage.filename"
                  preset="standard"
                  sizes="sm:100vw desk:1260px"
                  :preload="isTop"
                  :loading="isTop ? 'eager':'lazy'"
                  :src="blok.splashImage.filename"
                  :alt="blok.splashImage.alt"
                />
              </div>
            </div>
            <div
              class="absolute -bottom-16 -right-16 desk:-bottom-24 desk:-right-24 w-64 h-64"
              :class="{
                'desk:w-112 desk:h-112': blok.design === 'oneColumn',
                'desk:w-96 desk:h-96': blok.design !== 'oneColumn',
                'spin': blok.spinDiscountSplash,
              }"
            >
              <div
                v-if="blok.splashDiscountText || blok.splashDiscountHeading"
                :style="{backgroundColor : blok.splashDiscountBackgroundColor.color}"
                class="h-full w-full rounded-full flex flex-col items-center justify-center gap-2 text-center"
                :class="
                  { 'text-darkest': isLight(blok.splashDiscountBackgroundColor.color),
                    'text-lightest': !isLight(blok.splashDiscountBackgroundColor.color) }"
              >
                <div
                  v-if="blok.splashDiscountText"
                  class="-mt-2 mb-2 type-tagline-xxs desk:type-tagline"
                >
                  {{ blok.splashDiscountText }}
                </div>
                <div
                  v-if="blok.splashDiscountHeading"
                  class="type-headline-xs leading-single"
                  :class="{
                    'desk:type-headline-xl': blok.design === 'oneColumn',
                    'desk:type-headline-lg': blok.design !== 'oneColumn',
                  }"
                >
                  {{ blok.splashDiscountHeading }}
                </div>
              </div>
              <div v-else class="w-full h-full rounded-full overflow-hidden">
                <nuxt-img
                  v-if="blok.splashDiscountImage && blok.splashDiscountImage.filename"
                  preset="standard"
                  sizes="sm:100vw desk:1260px"
                  :preload="isTop"
                  :loading="isTop ? 'eager':'lazy'"
                  :src="blok.splashDiscountImage.filename"
                  :alt="blok.splashDiscountImage?.alt || ''"
                />
              </div>
            </div>
          </div>
          <div
            class="relative"
            :class="{
              'col-start-1 col-span-2 row-start-1 px-32': blok.design === 'oneColumn',
              'col-span-2 row-start-2 desk:col-span-1 desk:col-start-1 desk:row-start-1 pt-16 desk:pt-0': blok.design !== 'oneColumn',
            }"
          >
            <div
              class="flex flex-col justify-center gap-12 h-full"
              :class="{
                'items-center px-32 desk:px-0 text-center': blok.textAlign === 'center',
                'items-start pr-0 desk:pr-32': blok.textAlign !== 'center',
                'text-lightest': blok.taglineAndHeadlineInvertedText,
              }"
            >
              <div
                class="type-tagline dotted-border"
                :class="{ 'dotted-border-inv': blok.taglineAndHeadlineInvertedText, }"
              >
                {{ blok.tagline }}
              </div>
              <div class="type-headline-xl desk:type-headline-4xl">
                {{ blok.headline }}
              </div>
              <div class="wysiwyg"> <ExtendedWysiwyg :content="blok.content" /> </div>
              <div
                v-if="blok.buttonText"
                class="btn btn--arrow mt-8"
                :class="{
                  'btn--secondary': blok.buttonStyle === 'secondary',
                  'btn--ghost': blok.buttonStyle === 'ghost',
                }"
              >
                {{ blok.buttonText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import {
  StoryBlokPropBase,
  StoryBlokAsset,
  StoryBlokLink,
  StoryBlokColorpicker,
  StoryBlokVisualApperance
} from '~/constants/types/storyblok';
import { ISbRichtext } from 'storyblok-js-client';
import { checkIfVideoFile } from '~/util/imageUtil';
import { isLight } from '~/util/colorUtil';
const NuxtLink = resolveComponent('NuxtLink');

type SplashSelector = StoryBlokPropBase & {
  splash: string;
  splashImage: StoryBlokAsset;
}

type Props = StoryBlokPropBase & {
  tagline: string;
  headline: string;
  taglineAndHeadlineInvertedText: boolean;
  textAlign: 'left' | 'center';
  content: ISbRichtext;
  buttonText: string;
  buttonStyle: 'primary' | 'secondary' | 'ghost';
  link: StoryBlokLink;
  imageOrBackgroundVideo: StoryBlokAsset;
  desktopImageOrBackgroundVideo: StoryBlokAsset;
  image: StoryBlokAsset;
  desktopImage: StoryBlokAsset;
  backgroundColor: StoryBlokColorpicker;
  design: 'default' | 'oneColumn';
  topLeftSplash: SplashSelector[];
  bottomRightSplash: SplashSelector[];
  visualAppearance: StoryBlokVisualApperance;
  splashTopText: string,
  splashText: string,
  splashBackgroundColor: StoryBlokColorpicker;
  spinSplash: boolean,
  splashImage: StoryBlokAsset,
  splashDiscountImage: StoryBlokAsset,
  splashDiscountNumber: number,
  splashDiscountText: string,
  splashDiscountHeading: string,
  splashDiscountBackgroundColor: StoryBlokColorpicker;
  spinDiscountSplash: boolean;
  tapeBackgroundColor: StoryBlokColorpicker;
  tapeText: string;
  heroIfFirst: boolean;
}

const props = defineProps<{
  blok: Props,
  placement?: number,
}>();

const showTapes = computed(()=> {
  if (props.blok.splashImage &&  props.blok.splashImage.filename && props.blok.splashImage.filename !== '') {
    return false;
  }
  if (props.blok.splashDiscountImage &&  props.blok.splashDiscountImage.filename && props.blok.splashDiscountImage.filename !== '') {
    return false;
  }
  return props.blok.tapeText && props.blok.tapeBackgroundColor.color
    && props.blok.image && props.blok.image.filename
    && !props.blok.splashDiscountNumber && !props.blok.splashDiscountText;

});

const spashImageSizes = computed(()=> {
  return props.blok.design === 'oneColumn' ?
    'sm:100vw desk:896px deskStd:1244px deskLarge:1472px':
    'sm:100vw desk:436px deskStd:610px deskLarge:724px';
});

const isTop = computed(()=> {
  return (props.placement === 0);
});

</script>

<style scoped lang="postcss">
.spin {
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
